import React from "react"
import { useMutation } from "@apollo/client"
import { likeFeedItemMutate } from "../graphql/feed"
import { SendLikesQuery } from "../../sends/graphql/sends"

const LikeFeedItem = (props) => {
  const { sendSlug, render } = props

  const [likeFeedItem] = useMutation(likeFeedItemMutate, {
    update: (
      store,
      {
        data: {
          likeFeedItem: { likes },
        },
      },
    ) => {
      // add to send if only one
      if (sendSlug) {
        try {
          const data = store.readQuery({
            query: SendLikesQuery,
            variables: {
              slug: sendSlug,
            },
          })
          if (data) {
            const obj = {
              ...data,
              sendLikes: [...data.sendLikes, likes.slice(-1)],
            }
            store.writeQuery({
              query: SendLikesQuery,
              variables: {
                slug: sendSlug,
              },
              data: obj,
            })
          }
        } catch (e) {
          // not in the store
          // see: https://github.com/apollographql/apollo-feature-requests/issues/1
        }
      }
    },
  })

  return render({
    likeFeedItem,
  })
}

export default LikeFeedItem
