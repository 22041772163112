import { Avatar, Box, Flex, Heading, Link, Text } from "@chakra-ui/react"
import * as React from "react"
import Card from "../../common/components/Card"
import dayjs from "dayjs"
import duration from "dayjs/plugin/duration"
import localizedFormat from "dayjs/plugin/localizedFormat"
import utc from "dayjs/plugin/utc"
import { getExerciseTypeLabels } from "../../utilities/workouts"
import { problemFeatures, routeFeatures } from "../../config/features"
import { capitalizeText } from "../../utilities/text"
import NextLink from "next/link"

dayjs.extend(duration)
dayjs.extend(localizedFormat)
dayjs.extend(utc)

const ExerciseCounts = ({ workout }) => {
  const probsLength = workout.exercises.filter(
    (ex) =>
      ex.exerciseType === "problem" ||
      (ex.exerciseType === "grade" && ex.climbType === "problem"),
  ).length
  const routesLength = workout.exercises.filter(
    (ex) =>
      ex.exerciseType === "route" ||
      (ex.exerciseType === "grade" && ex.climbType === "route"),
  ).length
  const exercisesLength = workout.exercises.filter(
    (ex) =>
      ex.exerciseType === "fingerboard" ||
      ex.exerciseType === "campus" ||
      ex.exerciseType === "system" ||
      ex.exerciseType === "cardio" ||
      ex.exerciseType === "weights" ||
      ex.exerciseType === "video",
  ).length
  return (
    <Box
      alignItems="center"
      flexDirection="row"
      alignSelf="stretch"
      justifyContent={"flex-end"}
      mt={1}
    >
      {!!probsLength && !routesLength && (
        <>
          <Heading
            mb={0}
            textAlign="right"
            as="h6"
            size="sm"
            color="brand.orangeColor"
          >
            {probsLength}
            <Heading as="span" size="sm" color="brand.headingColor">
              {" "}
              {`Boulder${probsLength === 1 ? "" : "s"}`}
            </Heading>
          </Heading>
        </>
      )}
      {!!routesLength && !probsLength && (
        <>
          <Heading
            mb={0}
            textAlign="right"
            as="h6"
            size="sm"
            color="brand.orangeColor"
          >
            {routesLength}
            <Heading as="span" size="sm" color="brand.headingColor">
              {" "}
              {`Route${routesLength === 1 ? "" : "s"}`}
            </Heading>
          </Heading>
        </>
      )}
      {!!probsLength && !!routesLength && (
        <>
          <Heading
            mb={0}
            textAlign="right"
            as="h6"
            size="sm"
            color="brand.orangeColor"
          >
            {probsLength + routesLength}
            <Heading as="span" size="sm" color="brand.headingColor">
              {" "}
              Climbs
            </Heading>
          </Heading>
        </>
      )}
      {(!!probsLength || !!routesLength) && !!exercisesLength && (
        <Heading mb={0} textAlign="right" as="h6" size="sm">
          {" "}
          |{" "}
        </Heading>
      )}
      {!!exercisesLength && (
        <>
          <Heading
            mb={0}
            textAlign="right"
            as="h6"
            size="sm"
            color="brand.orangeColor"
          >
            {exercisesLength}
            <Heading as="span" size="sm" color="brand.headingColor">
              {" "}
              {`Exercise${exercisesLength === 1 ? "" : "s"}`}
            </Heading>
          </Heading>
        </>
      )}
    </Box>
  )
}
export interface IWorkoutCardProps {
  workout: any
  isBox?: boolean
}

export default function WorkoutCard({
  workout,
  isBox = false,
}: IWorkoutCardProps) {
  if (!workout) {
    return null
  }
  const { creator, totalTime, isCharCircuit } = workout
  const imageObjs = workout.exercises.reduce(
    (acc, ex) => {
      if (ex.image) {
        return [...acc, { image: ex.image }]
      }
      return acc
    },
    !!workout.image ? [{ image: workout.image }] : [],
  )
  const Container = isBox ? Box : Card

  const totalTimeDur = dayjs.duration(totalTime)
  const totalTimeStr = dayjs.utc(totalTimeDur.asMilliseconds()).format("H:mm")

  const tags = getExerciseTypeLabels(workout)
  const place = workout.gym || workout.guide
  const isGym = !!workout.gym
  const taggedCharLabels = (workout.taggedCharacteristics || []).map((char) => {
    return workout.circuitType === "problem"
      ? problemFeatures.find((feat) => feat.featureKey === char)?.title
      : routeFeatures.find((feat) => feat.featureKey === char)?.title
  })
  return (
    <Container>
      <>
        {!!imageObjs.length ? (
          <Box mb={2}>
            <Box flex={1} position="relative">
              <Box
                bgImage={`url(${imageObjs[0].image})`}
                bgSize="cover"
                bgRepeat="no-repeat"
                height={130}
              />
            </Box>
          </Box>
        ) : (
          <></>
        )}
        <Flex mx={3} justify="space-between">
          <Flex>
            <Avatar
              src={creator?.profile?.picture}
              name={creator.name}
              size="md"
              mr={3}
            />
            <Box mt={1}>
              <Heading mb={0} as="h5" size="sm">
                Creator:
              </Heading>
              <Heading mb={0} variant={"link"} as="h5" size="sm">
                <NextLink passHref href={`users/${creator.slug}`}>
                  <Link>{creator.name}</Link>
                </NextLink>
              </Heading>
            </Box>
          </Flex>
          <Flex direction="column" align="flex-end">
            <ExerciseCounts workout={workout} />
            <Heading mt={"2px"} as="h6" size="sm">
              Tried{" "}
              <Heading as="span" size="sm" color="brand.orangeColor">
                {workout.popularity || 0}
              </Heading>{" "}
              {`time${workout.popularity === 1 ? "" : `s`}`}{" "}
            </Heading>
          </Flex>
        </Flex>
        {!!place && (
          <Heading mt={2} mx={3} as="h6" size="sm">
            {isGym ? "Gym" : "Guide"}:{" "}
            <NextLink
              passHref
              href={`${isGym ? `/gyms/` : `/guides/`}${place.slug}`}
            >
              <Link>{place.name}</Link>
            </NextLink>
          </Heading>
        )}
        {!!isCharCircuit && (
          <Heading mt={2} mx={3} as="h6" size="sm">
            ~10 {workout.circuitType === "problem" ? "Boulder" : "Route"}s
          </Heading>
        )}
        <Flex my={2} mx={3} justify="space-between">
          <Box>
            <Heading mb={0} as="h4" size="lg" color="brand.orangeColor">
              {totalTimeStr}
            </Heading>
            <Heading as="h6" size="xs">
              Est Time (h:mm)
            </Heading>
          </Box>
          <Box display="flex" flexDirection={"column"} alignItems={"flex-end"}>
            {!!tags?.length && (
              <Box
                flexWrap="wrap"
                alignItems="flex-end"
                justifyContent={"flex-end"}
                flexDirection="row"
              >
                {tags.map((tag) => (
                  <Box
                    display="inline-block"
                    key={tag}
                    borderRadius={20}
                    borderWidth={1}
                    borderColor={"brand.headingColor"}
                    px={3}
                    py={1}
                    m={"2px"}
                    alignItems="center"
                    bg={"brand.whiteColor"}
                  >
                    <Heading mb={0} as="h6" size="xs">
                      {tag}
                    </Heading>
                  </Box>
                ))}
              </Box>
            )}
            {!!isCharCircuit && (
              <Box
                flexWrap="wrap"
                alignItems="flex-end"
                justifyContent={"flex-end"}
                flexDirection="row"
              >
                {taggedCharLabels.map((char) => (
                  <Box
                    borderRadius={6}
                    borderWidth={1}
                    borderColor={"brand.orangeColor"}
                    px={2}
                    py={"2px"}
                    my={"2px"}
                    mr={1}
                    alignItems="center"
                    bg={"brand.whiteColor"}
                    key={char}
                  >
                    <Heading mb={0} as="h6" size="xs" color="brand.orangeColor">
                      {capitalizeText(char)}
                    </Heading>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        </Flex>
      </>
    </Container>
  )
}
