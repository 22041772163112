import React from "react"
import dayjs from "dayjs"
import LocalizedFormat from "dayjs/plugin/localizedFormat"
import {
  Box,
  Flex,
  Heading,
  IconButton,
  Text,
  useTheme,
} from "@chakra-ui/react"
import Tooltip from "../../common/components/Tooltip"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes, faUser } from "@fortawesome/pro-light-svg-icons"
import TextLink from "../../common/components/TextLink"
import { useMutation } from "@apollo/client"
import { DELETE_COMMENT, GET_COMMENTS } from "../../guides/graphql/comments"
import { imageHandler } from "../../utilities/imageHandler"
import Card from "../../common/components/Card"
import useColors from "../../common/hooks/useColors"

dayjs.extend(LocalizedFormat)

const Comment = ({ loggedInUser, comment, type, _id }) => {
  const { blueColor } = useColors()
  const pic = comment?.user?.profile?.picture

  const [deleteComment] = useMutation(DELETE_COMMENT, {
    refetchQueries: [
      {
        query: GET_COMMENTS,
        variables: {
          _id,
          type,
        },
      },
    ],
  })

  const resizedPicture = imageHandler(comment?.user?.profile?.picture, {
    resize: { width: 60, fit: "cover" },
  })
  const isLoggedInUser = loggedInUser._id === comment?.user?._id
  return (
    <Flex my={2} direction={isLoggedInUser ? "row" : "row-reverse"}>
      {resizedPicture ? (
        <Box
          flex="none"
          backgroundImage={`url(${resizedPicture})`}
          borderRadius={"100%"}
          backgroundSize="cover"
          height={30}
          width={30}
        />
      ) : (
        <Box
          flex="none"
          borderRadius={"100%"}
          bg="white"
          height={30}
          width={30}
          alignItems="center"
          display="flex"
          justifyContent="center"
        >
          <FontAwesomeIcon size="2x" color={blueColor} icon={faUser} />
        </Box>
      )}
      <Card py={0} mx={2} maxWidth="75%">
        <Flex mt={2} mb={3}>
          <Box>
            <Heading
              fontWeight={"light"}
              variant="link"
              pt={2}
              size="sm"
              mb={0}
            >
              <TextLink href={`/users/${comment.user.slug}`}>
                {comment.user.name}
              </TextLink>
              <Box as="span" color={"brand.headingColor"}>
                {" "}
                {dayjs(comment.date).format("lll")}
              </Box>
            </Heading>
            <Text mt={2}>{comment.comment}</Text>
          </Box>
          {isLoggedInUser ? (
            <Box
              sx={{
                opacity: 0,
                _hover: {
                  opacity: 1,
                },
              }}
              mt={-1}
              ml={2}
              mr={-3}
            >
              <IconButton
                data-tip
                data-tooltip-id={`uncomment-btn-tt-${comment._id}`}
                onClick={() => {
                  deleteComment({
                    variables: {
                      _id: comment._id,
                    },
                  })
                }}
              >
                <FontAwesomeIcon color={blueColor} icon={faTimes} />
              </IconButton>
              <Tooltip
                class="card-tooltip"
                id={`uncomment-btn-tt-${comment._id}`}
              >
                <h6>Click to delete this comment.</h6>
              </Tooltip>
            </Box>
          ) : null}
        </Flex>
      </Card>
    </Flex>
  )
}

export default Comment
