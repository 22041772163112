import _ from "lodash"
import React from "react"
import Tooltip from "../../common/components/Tooltip"
import { Box, Heading, IconButton, useTheme } from "@chakra-ui/react"

import styles from "./css/FeedItemLikes.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons"
import Image from "next/image"
import useColors, { isDarkModeAtom } from "../../common/hooks/useColors"
import { useAtom } from "jotai"

const FeedItemLikes = ({
  feedItemSlug,
  loggedInUser,
  feedItemLikes,

  likeFeedItem,
  unlikeFeedItem,
}) => {
  const [isDarkMode] = useAtom(isDarkModeAtom)
  const { blueColor } = useColors()

  const [isLoading, setIsLoading] = React.useState(false)

  const hasLiked =
    loggedInUser &&
    _.find(feedItemLikes, { _id: loggedInUser._id }) !== undefined

  return (
    <>
      <IconButton
        data-tip
        data-tooltip-id={"like-btn-" + feedItemSlug + "tt"}
        sx={{
          bg: hasLiked ? "brand.orangeColor" : "transparent",
          _hover: {
            bg: hasLiked ? "brand.orangeColorHover" : "brand.whiteColorHover",
          },
        }}
        onClick={async () => {
          setIsLoading(true)
          if (hasLiked) {
            await unlikeFeedItem({
              variables: {
                slug: feedItemSlug,
              },
            })
          } else {
            await likeFeedItem({
              variables: {
                slug: feedItemSlug,
              },
            })
          }
          setIsLoading(false)
        }}
      >
        {isLoading ? (
          <FontAwesomeIcon
            size="2x"
            color={blueColor}
            icon={faSpinnerThird}
            spin
          />
        ) : (
          <Box
            alignSelf="stretch"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Image
              src={
                hasLiked
                  ? "/images/icons/fist-bump.svg"
                  : isDarkMode
                  ? "/images/icons/fist-bump-blue-dark.svg"
                  : "/images/icons/fist-bump-blue.svg"
              }
              alt="fist-bump"
              width={21}
              height={21}
              className={
                hasLiked ? styles["fist-bump-active"] : styles["fist-bump"]
              }
            />
          </Box>
        )}
      </IconButton>
      <Tooltip
        place="bottom"
        class="card-tooltip"
        id={"like-btn-" + feedItemSlug + "tt"}
      >
        <Heading as="h6" size="sm">
          {hasLiked ? "Unpound it" : "Pound It!"}
        </Heading>
      </Tooltip>
    </>
  )
}

FeedItemLikes.propTypes = {}

export default FeedItemLikes
