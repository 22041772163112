import gql from "graphql-tag"
import { mediumProblemFragment } from "../../guides/graphql/mediumProblemFragment"
import { mediumRouteFragment } from "../../guides/graphql/mediumRouteFragment"
import { basicUserFragment } from "../../users/graphql/basicUserFragment"
import { basicMediaFragment } from "../../media/graphql/basicMediaFragment"
import { basicSendFragment } from "./basicSendFragment"
import { feedCommentFragment } from "../../feed/graphql/feedCommentFragment"
import { basicAreaFragment } from "../../guides/graphql/basicAreaFragment"
import { basicGymAreaFragment } from "../../gyms/graphql/basicGymAreaFragment"
import { basicProblemFragment } from "../../guides/graphql/basicProblemFragment"
import { basicRouteFragment } from "../../guides/graphql/basicRouteFragment"

export const fullSendFragment = gql`
  fragment fullSendFragment on Send {
    ...basicSendFragment
    description
    shoes
    harness
    rope
    isAttempts
    problem {
      ...mediumProblemFragment
      comp {
        _id
        name
      }
      compSet {
        _id
        name
        start
        end
      }
      area {
        ...basicAreaFragment
      }
      guide {
        _id
        type
        slug
      }
      gymArea {
        ...basicGymAreaFragment
      }
      gym {
        _id
        type
        slug
        isShowingGrades
        isShowingOutdoorRouteGradeForm
        settingColors {
          color
          name
        }
        routeGradeColors {
          color
          name
          minGrade
          maxGrade
          image
        }
        problemGradeColors {
          color
          name
          minGrade
          maxGrade
          image
        }
      }
      firstAscent {
        ...basicSendFragment
      }
    }
    route {
      ...mediumRouteFragment
      area {
        ...basicAreaFragment
      }
      guide {
        _id
        type
        slug
      }
      gymArea {
        ...basicGymAreaFragment
      }
      gym {
        _id
        isShowingGrades
        isShowingOutdoorRouteGradeForm
        settingColors {
          color
          name
        }
        routeGradeColors {
          color
          name
          minGrade
          maxGrade
          image
        }
        problemGradeColors {
          color
          name
          minGrade
          maxGrade
          image
        }
      }
      firstAscent {
        ...basicSendFragment
      }
    }
    user {
      ...basicUserFragment
    }
    media {
      ...basicMediaFragment
    }
    isOnsight
    isFirstAscent
    isGreatest
  }
  ${mediumProblemFragment}
  ${mediumRouteFragment}
  ${basicUserFragment}
  ${basicMediaFragment}
  ${basicSendFragment}
  ${basicAreaFragment}
  ${basicGymAreaFragment}
`

export const SendQuery = gql`
  query SendQuery($slug: String!, $id: String) {
    send(slug: $slug, id: $id) {
      ...fullSendFragment
    }
  }
  ${fullSendFragment}
`
export const UserSendListQuery = gql`
  query UserSendListQuery(
    $id: String!
    $type: String!
    $limit: Int
    $sort: String
  ) {
    userSends(id: $id, type: $type, limit: $limit, sort: $sort) {
      ...fullSendFragment
    }
  }
  ${fullSendFragment}
`
export const SendCommentsQuery = gql`
  query SendCommentsQuery($slug: String!) {
    sendComments(slug: $slug) {
      ...feedCommentFragment
    }
  }
  ${feedCommentFragment}
`
export const SendLikesQuery = gql`
  query SendLikesQuery($slug: String!) {
    sendLikes(slug: $slug) {
      ...basicUserFragment
    }
  }
  ${basicUserFragment}
`
export const ClimbSendListQuery = gql`
  query ClimbSendListQuery(
    $id: String!
    $query: String
    $limit: Int
    $sort: String
  ) {
    climbSends(id: $id, query: $query, limit: $limit, sort: $sort) {
      ...fullSendFragment
    }
  }
  ${fullSendFragment}
`

export const GET_USER_SENDS = gql`
  query GetUserSends(
    $userId: String!
    $query: String!
    $sort: String
    $cursor: String
  ) {
    userSendsPaginated(
      userId: $userId
      query: $query
      sort: $sort
      cursor: $cursor
    ) {
      sends {
        ...fullSendFragment
      }
      cursor
      hasMore
    }
  }
  ${fullSendFragment}
`

export const GET_LAST_USER_SESSION = gql`
  query GetLastUserSession($userId: String) {
    getLastUserSession(userId: $userId) {
      ...basicSendFragment
      isAttempts
      problem {
        _id
        slug
        type
        grade
        gradeColor
        gradeName
        setterGrade
        numUserAttempts
        gym {
          _id
          type
          slug
          isOverlappingGrades
          isShowingGrades
          isShowingOutdoorRouteGradeForm
          routeGradeColors {
            color
            name
            minGrade
            maxGrade
            image
          }
          problemGradeColors {
            color
            name
            minGrade
            maxGrade
            image
          }
        }
        guide {
          _id
          type
          slug
        }
      }
      route {
        _id
        slug
        type
        grade
        gradeColor
        gradeName
        setterGrade
        numUserAttempts
        gym {
          _id
          type
          slug
          isOverlappingGrades
          isShowingGrades
          isShowingOutdoorRouteGradeForm
          routeGradeColors {
            color
            name
            minGrade
            maxGrade
            image
          }
          problemGradeColors {
            color
            name
            minGrade
            maxGrade
            image
          }
        }
        guide {
          _id
          type
          slug
        }
      }
    }
  }
  ${basicSendFragment}
`

export const GET_SEND_GYMS = gql`
  query GetSendGyms($userId: String!, $sinceDate: Date!) {
    getSendGyms(userId: $userId, sinceDate: $sinceDate) {
      _id
      name
      slug
      isOverlappingGrades
      isShowingGrades
      hasUserSentProblems
      hasUserSentRoutes
      routeGradeColors {
        color
        name
        minGrade
        maxGrade
        image
      }
      problemGradeColors {
        color
        name
        minGrade
        maxGrade
        image
      }
    }
  }
`

export const GET_GRADE_SENDS = gql`
  query GetGradeSends(
    $userId: String!
    $grade: Float!
    $climbType: String!
    $isIndoor: Boolean
    $gymId: String
    $guideId: String
    $cursor: String
  ) {
    getGradeSends(
      userId: $userId
      grade: $grade
      climbType: $climbType
      isIndoor: $isIndoor
      gymId: $gymId
      guideId: $guideId
      cursor: $cursor
    ) {
      cursor
      hasMore
      sends {
        ...basicSendFragment
      }
    }
  }
  ${basicSendFragment}
`

export const EDIT_SEND = gql`
  mutation EditSend($send: SendInput!) {
    editSend(send: $send) {
      ...fullSendFragment
    }
  }
  ${fullSendFragment}
`
export const CREATE_SEND = gql`
  mutation CreateSend($send: SendInput!, $competitorCode: String) {
    createSend(send: $send, competitorCode: $competitorCode) {
      ...basicSendFragment
      habitsComplete {
        isCompleteOnDay
        canComplete
        habit {
          _id
          name
        }
      }
    }
  }
  ${basicSendFragment}
`

export const DELETE_SEND = gql`
  mutation DeleteSend($slug: String!, $isSavingAttempts: Boolean) {
    deleteSend(slug: $slug, isSavingAttempts: $isSavingAttempts) {
      _id
      slug
      sendType
      problem {
        _id
        slug
      }
      route {
        _id
        slug
      }
    }
  }
`

export const commentOnSendMutate = gql`
  mutation commentOnSendMutate($slug: String!, $comment: String!) {
    commentOnSend(slug: $slug, comment: $comment) {
      ...feedCommentFragment
    }
  }
  ${feedCommentFragment}
`

export const uncommentOnSendMutate = gql`
  mutation uncommentOnSendMutate($slug: String!, $commentId: String!) {
    uncommentOnSend(slug: $slug, commentId: $commentId) {
      ...feedCommentFragment
    }
  }
  ${feedCommentFragment}
`

export const likeSendMutate = gql`
  mutation likeSendMutate($slug: String!) {
    likeSend(slug: $slug) {
      ...basicUserFragment
    }
  }
  ${basicUserFragment}
`

export const unlikeSendMutate = gql`
  mutation unlikeSendMutate($slug: String!) {
    unlikeSend(slug: $slug) {
      ...basicUserFragment
    }
  }
  ${basicUserFragment}
`

export const ADD_ATTEMPT = gql`
  mutation AddAttempt($attempt: AttemptInput!) {
    addAttempt(attempt: $attempt) {
      _id
      problem {
        ...basicProblemFragment
        numUserAttempts
      }
      route {
        ...basicRouteFragment
        numUserAttempts
      }
      grade
      climbType
      habitsComplete {
        isCompleteOnDay
        canComplete
        habit {
          _id
          name
        }
      }
    }
  }
  ${basicProblemFragment}
  ${basicRouteFragment}
`

export const REMOVE_ATTEMPT = gql`
  mutation RemoveAttempt($id: String!) {
    removeAttempt(id: $id) {
      _id
    }
  }
`

export const REMOVE_LATEST_ATTEMPT = gql`
  mutation RemoveLatestAttempt($climbId: String, $climbType: String!) {
    removeLatestAttempt(climbId: $climbId, climbType: $climbType) {
      _id
      problem {
        ...mediumProblemFragment
        numUserAttempts
      }
      route {
        ...mediumRouteFragment
        numUserAttempts
      }
    }
  }
  ${mediumProblemFragment}
  ${mediumRouteFragment}
`

export const SET_SEND_GRADE = gql`
  mutation SetSendGrade($id: String!, $grade: Float!) {
    setSendGrade(id: $id, grade: $grade) {
      ...basicSendFragment
    }
  }
  ${basicSendFragment}
`

export const DELETE_ATTEMPTS = gql`
  mutation DeleteAttempts($attemptIds: [String!]!) {
    deleteAttempts(attemptIds: $attemptIds) {
      isSuccessful
      error
    }
  }
`

export default {
  fullSendFragment,
  SendQuery,
  UserSendListQuery,
  SendCommentsQuery,
  SendLikesQuery,
  CREATE_SEND,
  EDIT_SEND,
  DELETE_SEND,
  commentOnSendMutate,
  likeSendMutate,
  ADD_ATTEMPT,
  REMOVE_ATTEMPT,
  SET_SEND_GRADE,
}
