import React from "react"
import LikeFeedItem from "../graphqlComponents/LikeFeedItem"
import UnlikeFeedItem from "../graphqlComponents/UnlikeFeedItem"

const FeedItemLikesCont = (props) => {
  const { loggedInUser, feedItemId, sendSlug, render } = props

  return (
    <LikeFeedItem
      loggedInUser={loggedInUser}
      feedItemId={feedItemId}
      sendSlug={sendSlug}
      render={({ likeFeedItem }) => (
        <UnlikeFeedItem
          loggedInUser={loggedInUser}
          feedItemId={feedItemId}
          sendSlug={sendSlug}
          render={({ unlikeFeedItem }) =>
            render({
              likeFeedItem,
              unlikeFeedItem,
            })
          }
        />
      )}
    />
  )
}

export default FeedItemLikesCont
