import React, { useState, useEffect, useContext } from "react"
import { Box, useTheme } from "@chakra-ui/react"
import {
  CarouselProvider,
  CarouselContext,
  Slider,
  Slide,
  Dot,
} from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"
import useColors from "../../common/hooks/useColors"

const Dots = ({ children }) => {
  const { headingColor, bgColor } = useColors()
  const carouselContext = useContext(CarouselContext)
  const [currentSlide, setCurrentSlide] = useState(
    carouselContext.state.currentSlide,
  )
  useEffect(() => {
    function onChange() {
      setCurrentSlide(carouselContext.state.currentSlide)
    }
    carouselContext.subscribe(onChange)
    return () => carouselContext.unsubscribe(onChange)
  }, [carouselContext])
  return (
    <Box
      position="absolute"
      bottom={3}
      width="100%"
      display="flex"
      justifyContent="center"
    >
      {children
        .filter((c) => !!c?.key)
        .map((child, ind) => (
          <Dot
            key={child.key}
            slide={ind}
            style={{
              width: 12,
              height: 12,
              background: ind === currentSlide ? headingColor : bgColor,
              borderRadius: "100%",
              display: "block",
              margin: "0 5px",
            }}
          />
        ))}
    </Box>
  )
}

const Carousel = ({
  children: childs,
  height = null,
  isPlaying = false,
  orientation = "horizontal",
}) => {
  // doing this so can pass in 'null' as child if needed
  const children = childs.filter((child) => !!child?.key)

  return (
    <CarouselProvider
      naturalSlideWidth={100}
      naturalSlideHeight={height ?? 80}
      totalSlides={children.length}
      isPlaying={isPlaying}
      interval={10000}
      infinite
      orientation={orientation}
    >
      <Slider>
        {children.map((child, ind) => (
          <Slide key={child.key} index={ind}>
            {child}
          </Slide>
        ))}
      </Slider>
      <Box
        position={"relative"}
        zIndex={2}
        justifyContent={"center"}
        display="flex"
      >
        {childs.length > 1 && <Dots>{childs}</Dots>}
      </Box>
    </CarouselProvider>
  )
}

export default Carousel
