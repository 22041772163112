import { exerciseTypes, featuredWorkoutSections } from "../config/workouts"
import _ from "lodash"
import { getGradeColor, getGradeLabel } from "./grades"

export function getProblems(workout) {
  if (!workout) {
    return []
  }
  const probs = workout.exercises
    .filter((ex) => !!ex.problem)
    .map((ex) => ex.problem)
  const gradeProbs = workout.exercises
    .filter((ex) => ex.exerciseType === "grade" && ex.climbType === "problem")
    .map((ex) => ({
      ...ex,
      type: "problem",
    }))
  return [...probs, ...gradeProbs]
}

export function getRoutes(workout) {
  if (!workout) {
    return []
  }
  const routes = workout.exercises
    .filter((ex) => !!ex.route)
    .map((ex) => ex.route)
  const gradeRoutes = workout.exercises
    .filter((ex) => ex.exerciseType === "grade" && ex.climbType === "route")
    .map((ex) => ({
      ...ex,
      type: "route",
    }))
  return [...routes, ...gradeRoutes]
}

function getTypeLabel(value) {
  const opt = exerciseTypes.find((obj) => obj.value === value)
  if (opt) {
    return opt.label
  }
  return value === "route" ? "Routes" : "Boulders"
}

export function getExerciseTypeLabels(workout) {
  const values = workout.exercises.reduce((acc, ex) => {
    if (ex.videoExerciseTypes?.length) {
      return [...acc, ...ex.videoExerciseTypes, "video"]
    }
    return [...acc, ex.exerciseType]
  }, [])
  let labels = _.uniq(values).map((ex) => getTypeLabel(ex))
  const isCircuit = workout.exercises.some(
    (ex) => ex.exerciseType === "problem" || ex.exerciseType === "route",
  )
  if (isCircuit) {
    labels = [...labels, "Circuit"]
  }
  return labels
}

export function isClimbExercise(exercise) {
  return (
    exercise.exerciseType === "problem" ||
    exercise.exerciseType === "route" ||
    exercise.exerciseType === "grade"
  )
}

export function doesSendMatchExercise({ send, exercise, gym, gradeType }) {
  if (!send) {
    return false
  }
  if (send?.problem && send?.problem?._id === exercise?.problem?._id) {
    return true
  }
  if (send?.route && send?.route?._id === exercise?.route?._id) {
    return true
  }
  const climb = send?.problem || send?.route
  const grade = climb.setterGrade || climb.grade
  if (
    climb &&
    exercise.exerciseType === "grade" &&
    climb.type === exercise.climbType
  ) {
    if (!gym || gym?.isShowingGrades) {
      const climbGradeLabel = getGradeLabel(climb.grade, climb.type, gradeType)
      const exerciseGradeLabel = getGradeLabel(
        exercise.grade,
        exercise.climbType,
        gradeType,
      )
      return climbGradeLabel === exerciseGradeLabel
    } else {
      const climbCol = getGradeColor(grade, gym?.[`${climb.type}GradeColors`])
      const exerciseCol = getGradeColor(
        exercise.grade,
        gym?.[`${climb.type}GradeColors`],
      )
      return climbCol === exerciseCol
    }
  }
}

export function getHardestUserGrades(user) {
  const {
    hardestCurrentIndoorRouteSendGrade,
    hardestCurrentIndoorProblemSendGrade,
    hardestCurrentOutdoorProblemSendGrade,
    hardestCurrentOutdoorRouteSendGrade,
  } = user
  const hardestProblemGrade = Math.round(
    Math.max(
      hardestCurrentIndoorProblemSendGrade ?? 0,
      hardestCurrentOutdoorProblemSendGrade ?? 0,
    ),
  )
  const hardestRouteGrade = Math.round(
    Math.max(
      hardestCurrentIndoorRouteSendGrade ?? 0,
      hardestCurrentOutdoorRouteSendGrade ?? 0,
    ),
  )
  return {
    hardestProblemGrade,
    hardestRouteGrade,
  }
}

export function getClimbingWorkouts(loggedInUser) {
  const { hardestProblemGrade: hardestP, hardestRouteGrade: hardestR } =
    getHardestUserGrades(loggedInUser)
  return [
    {
      title: "Personalized Bouldering",
      type: "problem",
      isClimbs: true,
      sectionIndex: 0,
      data: [
        [
          {
            name: "Limit Pyramid",
            _id: "",
            image:
              "https://gym-brand-images.s3.amazonaws.com/limit-bouldering.jpg",
            screen: "Recommendations",
            params: {
              climbType: "problem",
              isIndoor: true,
              title: "Limit Pyramid",
              description:
                "For this limit bouldering circuit, don't expect to complete all the problems. Spend at most 20 minutes on each problem with no more than 4 full efforts on any one problem.",
              distribution: [
                {
                  minGrade: Math.max(0, hardestP - 7),
                  maxGrade: Math.max(hardestP - 5, 6),
                  amount: 6,
                },
                {
                  minGrade: Math.max(0, hardestP - 4),
                  maxGrade: Math.max(hardestP - 2, 6),
                  amount: 4,
                },
                {
                  minGrade: Math.max(0, hardestP - 1),
                  maxGrade: Math.max(hardestP + 1, 6),
                  amount: 2,
                },
              ],
            },
          },
          {
            name: "Volume Pyramid",
            _id: "",
            image:
              "https://gym-brand-images.s3.amazonaws.com/volume-bouldering.jpg",
            screen: "Recommendations",
            params: {
              climbType: "problem",
              isIndoor: true,
              title: "Volume Pyramid",
              description:
                "For this volume bouldering circuit, try to complete each problem first try and rest at most 4 or 5 minutes between tries on the harder problems.",
              distribution: [
                {
                  minGrade: hardestP - 8,
                  maxGrade: Math.max(hardestP - 6, 6),
                  amount: 14,
                },
                {
                  minGrade: hardestP - 5,
                  maxGrade: Math.max(hardestP - 3, 6),
                  amount: 6,
                },
              ],
            },
          },
        ],
        [
          {
            name: "Crimper’s Paradise",
            isCharacteristic: true,
            _id: "",
            image:
              "https://gym-brand-images.s3.amazonaws.com/crimp-workout.jpg",
            screen: "Recommendations",
            params: {
              climbType: "problem",
              isIndoor: true,
              title: "Crimper’s Paradise",
              description:
                "Need to get in shape for your outside projects? Your fingers will get strong in no time on this crimpy circuit.",
              distribution: [
                {
                  minGrade: Math.max(0, hardestP - 7),
                  maxGrade: Math.max(hardestP - 5, 6),
                  amount: 6,
                },
                {
                  minGrade: Math.max(0, hardestP - 4),
                  maxGrade: Math.max(hardestP - 2, 6),
                  amount: 4,
                },
                {
                  minGrade: Math.max(0, hardestP - 1),
                  maxGrade: Math.max(hardestP + 1, 6),
                  amount: 2,
                },
              ],
              queryFilter: {
                crimpy: true,
                slopey: null,
              },
            },
          },
          {
            name: "Pinches for Days!",
            isCharacteristic: true,
            _id: "",
            image:
              "https://gym-brand-images.s3.amazonaws.com/pinches-workout.jpg",
            screen: "Recommendations",
            params: {
              climbType: "problem",
              isIndoor: true,
              title: "Pinches for Days!",
              description:
                "Get your thumb involved on these problems that feature an abundance of pinches.",
              distribution: [
                {
                  minGrade: Math.max(0, hardestP - 7),
                  maxGrade: Math.max(hardestP - 5, 6),
                  amount: 6,
                },
                {
                  minGrade: Math.max(0, hardestP - 4),
                  maxGrade: Math.max(hardestP - 2, 6),
                  amount: 4,
                },
                {
                  minGrade: Math.max(0, hardestP - 1),
                  maxGrade: Math.max(hardestP + 1, 6),
                  amount: 2,
                },
              ],
              queryFilter: {
                pinchy: true,
              },
            },
          },
        ],
        [
          {
            name: "Slappin’ Slopers",
            isCharacteristic: true,
            _id: "",
            image:
              "https://gym-brand-images.s3.amazonaws.com/slopey-workout.jpg",
            screen: "Recommendations",
            params: {
              climbType: "problem",
              isIndoor: true,
              title: "Slappin’ Slopers",
              description:
                "Want to work your sloper strength? Try this circuit of especially slopey problems.",
              distribution: [
                {
                  minGrade: Math.max(0, hardestP - 7),
                  maxGrade: Math.max(hardestP - 5, 6),
                  amount: 6,
                },
                {
                  minGrade: Math.max(0, hardestP - 4),
                  maxGrade: Math.max(hardestP - 2, 6),
                  amount: 4,
                },
                {
                  minGrade: Math.max(0, hardestP - 1),
                  maxGrade: Math.max(hardestP + 1, 6),
                  amount: 2,
                },
              ],
              queryFilter: {
                slopey: true,
                crimpy: null,
              },
            },
          },
          {
            name: "Pocket Power!",
            isCharacteristic: true,
            _id: "",
            image:
              "https://gym-brand-images.s3.amazonaws.com/pocket-workout.jpg",
            screen: "Recommendations",
            params: {
              climbType: "problem",
              isIndoor: true,
              title: "Pocket Power!",
              description:
                "You'll need accuracy and some strong middle fingers to climb these pockety problems at your limit.",
              distribution: [
                {
                  minGrade: Math.max(0, hardestP - 7),
                  maxGrade: Math.max(hardestP - 5, 6),
                  amount: 6,
                },
                {
                  minGrade: Math.max(0, hardestP - 4),
                  maxGrade: Math.max(hardestP - 2, 6),
                  amount: 4,
                },
                {
                  minGrade: Math.max(0, hardestP - 1),
                  maxGrade: Math.max(hardestP + 1, 6),
                  amount: 2,
                },
              ],
              queryFilter: {
                pockety: true,
              },
            },
          },
        ],
        // [

        //   {
        //     name: "Compression Session",
        //     _id: "",
        //     image:
        //       "https://gym-brand-images.s3.amazonaws.com/limit-bouldering.jpg",
        //     screen: "Recommendations",
        //     params: {
        //       climbType: "problem",
        //       isIndoor: true,
        //       title: "Compression Session",
        //       description:
        //         "For this limit bouldering circuit, don't expect to complete all the problems. Spend at most 20 minutes on each problem with no more than 4 full efforts on any one problem.",
        //       distribution: [
        //         {
        //           minGrade: Math.max(0, hardestP - 7),
        //           maxGrade: Math.max(hardestP - 5, 6),
        //           amount: 6,
        //         },
        //         {
        //           minGrade: Math.max(0, hardestP - 4),
        //           maxGrade: Math.max(hardestP - 2, 6),
        //           amount: 4,
        //         },
        //         {
        //           minGrade: Math.max(0, hardestP - 1),
        //           maxGrade: Math.max(hardestP + 1, 6),
        //           amount: 2,
        //         },
        //       ],
        //       queryFilter: {
        //         compression: true,
        //       },
        //     },
        //   },
        // ]
        // [
        //   {
        //     name: "Create your own",
        //     _id: "",
        //     image:
        //       "https://gym-brand-images.s3.amazonaws.com/custom-bouldering.jpg",
        //   },
        // ],
      ],
    },
    {
      title: "Personalized Routes",
      isClimbs: true,
      type: "route",
      data: [
        [
          {
            name: "Limit Pyramid",
            image: "https://gym-brand-images.s3.amazonaws.com/limit-route.jpg",
            screen: "Recommendations",
            params: {
              climbType: "route",
              isIndoor: true,
              title: "Limit Pyramid",
              description:
                "For this limit pyramid, try each route once and stop trying it if you fall or take more than 2 times. Rest 15 - 20 minutes between routes.",
              distribution: [
                {
                  minGrade: hardestR - 8,
                  maxGrade: Math.max(hardestR - 5, 9),
                  amount: 2,
                },
                {
                  minGrade: hardestR - 4,
                  maxGrade: Math.max(hardestR - 2, 9),
                  amount: 2,
                },
                {
                  minGrade: hardestR - 1,
                  maxGrade: Math.max(hardestR + 1, 9),
                  amount: 2,
                },
              ],
            },
          },
          {
            name: "Volume Pyramid",
            image: "https://gym-brand-images.s3.amazonaws.com/volume-route.jpg",
            screen: "Recommendations",
            params: {
              climbType: "route",
              isIndoor: true,
              title: "Volume Pyramid",
              description:
                "For this volume pyramid, try each route once and rest 10 - 15 minutes between routes.",
              distribution: [
                {
                  minGrade: hardestR - 8,
                  maxGrade: Math.max(hardestR - 5, 9),
                  amount: 6,
                },
                {
                  minGrade: hardestR - 4,
                  maxGrade: Math.max(hardestR - 2, 9),
                  amount: 4,
                },
              ],
            },
          },
        ],
        [
          {
            name: "Pumpfest!",
            isCharacteristic: true,
            image:
              "https://gym-brand-images.s3.amazonaws.com/pumpy-workout.jpg",
            screen: "Recommendations",
            params: {
              climbType: "route",
              isIndoor: true,
              title: "Pumpfest!",
              description:
                "See how long you can hold on when you try these especially pumpy routes.",
              distribution: [
                {
                  minGrade: hardestR - 8,
                  maxGrade: Math.max(hardestR - 5, 9),
                  amount: 2,
                },
                {
                  minGrade: hardestR - 4,
                  maxGrade: Math.max(hardestR - 2, 9),
                  amount: 2,
                },
                {
                  minGrade: hardestR - 1,
                  maxGrade: Math.max(hardestR + 1, 9),
                  amount: 2,
                },
              ],
              queryFilter: {
                pumpy: true,
                powerful: null,
              },
            },
          },
          {
            name: "Tech it out",
            isCharacteristic: true,
            image:
              "https://gym-brand-images.s3.amazonaws.com/technical-workout.jpg",
            screen: "Recommendations",
            params: {
              climbType: "route",
              isIndoor: true,
              title: "Tech it out",
              description:
                "This circuit features technical routes that will test your focus and determination.",
              distribution: [
                {
                  minGrade: hardestR - 8,
                  maxGrade: Math.max(hardestR - 5, 9),
                  amount: 2,
                },
                {
                  minGrade: hardestR - 4,
                  maxGrade: Math.max(hardestR - 2, 9),
                  amount: 2,
                },
                {
                  minGrade: hardestR - 1,
                  maxGrade: Math.max(hardestR + 1, 9),
                  amount: 2,
                },
              ],
              queryFilter: {
                technical: true,
              },
            },
          },
        ],
        // [
        //   {
        //     name: "Create your own",
        //     _id: "",
        //     image: "https://gym-brand-images.s3.amazonaws.com/custom-route.jpg",
        //   },
        // ],
      ],
    },
  ]
}

export const getFeaturedWorkoutSections = ({
  isAddingToHabit,
  loggedInUser,
}) => {
  return [
    ...(isAddingToHabit ? [] : getClimbingWorkouts(loggedInUser)),
    ...featuredWorkoutSections,
  ]
}
