import _ from "lodash"
import React from "react"
import { useQuery } from "@apollo/client"
import { SlideDown } from "react-slidedown"
import Comment from "./Comment"
import { GET_COMMENTS } from "../../guides/graphql/comments"
import {
  getFeedFeatures,
  getFeedFeatureType,
} from "../../utilities/feedFeatures"
import { commentableFeedItems } from "../../config/feedItemTypes"

import styles from "./css/FeedItemComments.module.css"
import CommentInput from "./CommentInput"
import { Box } from "@chakra-ui/react"

const FeedItemComments = (props) => {
  const { feedItem, loggedInUser, isShowingComments } = props
  const feats = getFeedFeatures(feedItem)
  const featType = getFeedFeatureType(feedItem)
  const type = commentableFeedItems.includes(featType) ? featType : "feedItem"
  const _id = type === "feedItem" ? feedItem?._id : feats?.[0]?._id
  const { loading, error, data } = useQuery(GET_COMMENTS, {
    variables: {
      type,
      _id,
    },
    skip: !_id,
  })
  const comments = data?.getComments?.comments || []

  return (
    <SlideDown className={styles["comments-slide-down"]}>
      {isShowingComments && !loading ? (
        <>
          {loggedInUser ? (
            <CommentInput loggedInUser={loggedInUser} type={type} _id={_id} />
          ) : null}
          <Box mx={6} mb={6}>
            {!loading
              ? comments.map((comment) => (
                  <Comment
                    key={comment._id}
                    comment={comment}
                    type={type}
                    _id={_id}
                    loggedInUser={loggedInUser}
                  />
                ))
              : null}
          </Box>
        </>
      ) : null}
    </SlideDown>
  )
}

FeedItemComments.propTypes = {}

export default FeedItemComments
