import { Box, useTheme } from "@chakra-ui/react"
import * as React from "react"
import useColors from "../../common/hooks/useColors"

// export interface IAbsoluteGradientProps {}

export default function AbsoluteGradient() {
  const { insetColor } = useColors()
  return (
    <Box
      position={"absolute"}
      top={0}
      left={0}
      right={0}
      bottom={0}
      borderTopWidth={1}
      borderBottomWidth={1}
      borderColor={insetColor}
      bg={insetColor}
    />
  )

  // return (
  //   <Box
  //     position="absolute"
  //     top={0}
  //     left={0}
  //     right={0}
  //     bottom={0}
  //     bg={`linear-gradient(45deg, ${startGradient} 0%, ${endGradient} 100%)`}
  //   />
  // )
}
