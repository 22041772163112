import gql from "graphql-tag"
import { basicUserFragment } from "../../users/graphql/basicUserFragment"

export const commentFragment = gql`
  fragment commentFragment on Comment {
    _id
    type
    date
    comment
    user {
      ...basicUserFragment
    }
    problem {
      _id
      type
      name
      slug
    }
    route {
      _id
      type
      name
      slug
    }
    area {
      _id
      type
      name
      slug
    }
    guide {
      _id
      type
      name
      slug
    }
  }
  ${basicUserFragment}
`

export const GET_COMMENTS = gql`
  query GetComments($type: String!, $_id: String!, $cursor: String) {
    getComments(type: $type, _id: $_id, cursor: $cursor) {
      comments {
        ...commentFragment
      }
      cursor
      hasMore
      isSubscribed
    }
  }
  ${commentFragment}
`

export const CREATE_COMMENT = gql`
  mutation CreateComment($type: String!, $_id: String!, $comment: String!) {
    createComment(type: $type, _id: $_id, comment: $comment) {
      ...commentFragment
    }
  }
  ${commentFragment}
`

export const DELETE_COMMENT = gql`
  mutation DeleteComment($_id: String!) {
    deleteComment(_id: $_id) {
      ...commentFragment
    }
  }
  ${commentFragment}
`

export const EDIT_COMMENT = gql`
  mutation EditComment($_id: String!, $comment: String!) {
    editComment(_id: $_id, comment: $comment) {
      ...commentFragment
    }
  }
  ${commentFragment}
`

export const SUBSCRIBE_TO_COMMENTS = gql`
  mutation SubscribeToComments($_id: String!, $type: String!) {
    subscribeToComments(_id: $_id, type: $type) {
      isSuccessful
      error
    }
  }
`

export const UNSUBSCRIBE_FROM_COMMENTS = gql`
  mutation UnsubscribeFromComments($_id: String!, $type: String!) {
    unsubscribeFromComments(_id: $_id, type: $type) {
      isSuccessful
      error
    }
  }
`

export const REPORT_COMMENTS = gql`
  mutation ReportComments($_id: String!, $type: String!, $message: String!) {
    reportComments(_id: $_id, type: $type, message: $message) {
      isSuccessful
      error
    }
  }
`
