import React from "react"
import { Box, useTheme } from "@chakra-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import useColors from "../../common/hooks/useColors"

const IconToggle = ({ icon, isError, raised, onClick, ref, ...props }) => {
  const { blueColor, orangeColor, whiteColor } = useColors()
  return (
    <Box
      ref={ref}
      as="button"
      bg={
        raised
          ? isError
            ? "brand.orangeColor"
            : "brand.blueColor"
          : "transparent"
      }
      borderRadius="100%"
      alignItems="center"
      justifyContent="center"
      boxShadow={raised ? "0px 1px 5px rgb(0 0 0 / 10%)" : "none"}
      height={props.mini ? "1.5rem" : "3rem"}
      width={props.mini ? "1.5rem" : "3rem"}
      onClick={onClick}
      sx={{
        backgroundSize: "cover",
        backgroundImage: props.picture ? `url(${props.picture})` : "none",
        _hover: {
          bg: raised
            ? isError
              ? "brand.orangeColorHover"
              : "brand.blueColorHover"
            : "brand.whiteColorHover",
        },
      }}
      {...props}
    >
      {!!icon && (
        <FontAwesomeIcon
          icon={icon}
          color={raised ? whiteColor : isError ? orangeColor : blueColor}
          size={props.mini ? "lg" : "xl"}
        />
      )}
      {props.children}
    </Box>
  )
}

export default IconToggle
