import React from "react"
import Rater from "react-rater"
import { Box, Flex, Heading } from "@chakra-ui/react"
import {
  CREATE_PROBLEM,
  CREATE_ROUTE,
  CREATE_SEND,
} from "../../config/feedItemTypes"
import GradeContext from "../../context/GradeContext"
import ClimbGrade from "../../common/components/ClimbGrade"

import styles from "./css/FeedItemStats.module.css"

const FeedItemStats = (props) => {
  const { feedItem, features } = props

  const statEventTypes = [CREATE_PROBLEM, CREATE_SEND, CREATE_ROUTE]

  if (
    statEventTypes.includes(feedItem.eventType) === false ||
    features.length !== 1
  ) {
    return null
  }

  const climb =
    features[0].type === "send"
      ? features[0].sendType === "problem"
        ? features[0].problem
        : features[0].route
      : features[0]

  return (
    <GradeContext.Consumer>
      {(gradeType) => (
        <Flex justify="flex-start" direction={"row"} align="center" wrap="wrap">
          <Box px={5}>
            <Heading as="h4" size="xl">
              <ClimbGrade
                climb={climb}
                gym={climb.gym}
                gradeType={gradeType}
                dotSize={23}
                isCenter
              />
            </Heading>
            <Heading as="h6" size="xs" mt={-2} islight="true">
              Grade
            </Heading>
          </Box>
          <Box
            borderLeft="1px solid"
            borderColor="brand.headingLineColor"
            px={5}
          >
            <Box my={"7px"}>
              <Rater
                className={styles["rater"] + " react-rater react-rater--medium"}
                interactive={false}
                total={5}
                rating={climb.quality || 0}
              />
            </Box>
            <Heading as="h6" size="xs" mt={"-7px"} islight="true">
              Quality
            </Heading>
          </Box>
          {feedItem.eventType === CREATE_SEND ? (
            <Box
              borderLeft="1px solid"
              borderColor="brand.headingLineColor"
              px={5}
            >
              <Heading as="h4" size="xl">
                {features[0].tries || "--"}
              </Heading>
              <Heading as="h6" size="xs" mt={-2} islight="true">
                {features[0].tries === 1 ? "Try" : "Tries"}
              </Heading>
            </Box>
          ) : (
            <Box
              borderLeft="1px solid"
              borderColor="brand.headingLineColor"
              px={5}
            >
              <Heading as="h4" size="xl">
                {climb.totalAscents}
              </Heading>
              <Heading as="h6" size="xs" mt={-2} islight="true">
                Sends
              </Heading>
            </Box>
          )}
        </Flex>
      )}
    </GradeContext.Consumer>
  )
}

FeedItemStats.propTypes = {}

export default FeedItemStats
