const textColor = "rgba(0, 0, 0, .6)"
const whiteColor = "rgba(255, 255, 255, 1)"
export const sendMilestones = [
  1, 25, 50, 75, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 2000, 3000,
  4000, 5000,
]

export const climbCreationMilestones = [
  1, 10, 25, 50, 75, 100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650,
  700, 750, 800, 850, 900, 950, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500,
  5000,
]

export const workoutMilestones = [
  1, 10, 25, 50, 75, 100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650,
  700, 750, 800, 850, 900, 950, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500,
  5000,
]

export const streakMilestones = [1, 3, 5, 10, 20, 30, 40, 52]

export const pyramidMilestones = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 25, 50]

// achievementTypes
export const SESSION_ONE_DAY_A_WEEK_STREAK = "SESSION_ONE_DAY_A_WEEK_STREAK"
export const SESSION_TWO_DAYS_A_WEEK_STREAK = "SESSION_TWO_DAYS_A_WEEK_STREAK"
export const INDOOR_PROBLEM_GRADE = "INDOOR_PROBLEM_GRADE"
export const INDOOR_ROUTE_GRADE = "INDOOR_ROUTE_GRADE"
export const OUTDOOR_PROBLEM_GRADE = "OUTDOOR_PROBLEM_GRADE"
export const OUTDOOR_ROUTE_GRADE = "OUTDOOR_ROUTE_GRADE"
export const NUMBER_OF_SENDS = "NUMBER_OF_SENDS"
export const NUMBER_OF_WORKOUTS = "NUMBER_OF_WORKOUTS"
export const CREATED_PROBLEMS = "CREATED_PROBLEMS"
export const CREATED_ROUTES = "CREATED_ROUTES"
export const COMPLETED_PYRAMID = "COMPLETED_PYRAMID"

export const importanceOfAchievements = [
  COMPLETED_PYRAMID,
  OUTDOOR_PROBLEM_GRADE,
  OUTDOOR_ROUTE_GRADE,
  INDOOR_PROBLEM_GRADE,
  INDOOR_ROUTE_GRADE,
  NUMBER_OF_SENDS,
  NUMBER_OF_WORKOUTS,
  SESSION_TWO_DAYS_A_WEEK_STREAK,
  SESSION_ONE_DAY_A_WEEK_STREAK,
  CREATED_PROBLEMS,
  CREATED_ROUTES,
]

export const badgeOptions = {
  SESSION_ONE_DAY_A_WEEK_STREAK: {
    line1: "WEEK",
    line2: "OF 1 SESSION",
    canPluralize: true,
    getTextColor: () => textColor,
    getBadge: () => "https://pebble-badges.s3.amazonaws.com/streak-badge-1.png",
    getEmailBadge: () =>
      "https://pebble-email-templates.s3.amazonaws.com/badges/streak-badge-1.png",
  },
  SESSION_TWO_DAYS_A_WEEK_STREAK: {
    line1: "WEEK",
    line2: "OF 2 SESSIONS",
    canPluralize: true,
    getTextColor: () => whiteColor,
    getBadge: () => "https://pebble-badges.s3.amazonaws.com/streak-badge-2.png",
    getEmailBadge: () =>
      "https://pebble-email-templates.s3.amazonaws.com/badges/streak-badge-2.png",
  },
  NUMBER_OF_SENDS: {
    line1: "SEND",
    canPluralize: true,
    getTextColor: (val) => (val % 100 === 0 ? whiteColor : textColor),
    getFeedItemText: (item) => `Sent their ${item.achievementValue}th climb!`,
    getBadge: (val) =>
      val % 100 === 0
        ? "https://pebble-badges.s3.amazonaws.com/sends-badge-2.png"
        : "https://pebble-badges.s3.amazonaws.com/sends-badge-1.png",
    getEmailBadge: (val) =>
      val % 100 === 0
        ? "https://pebble-email-templates.s3.amazonaws.com/badges/sends-badge-2.png"
        : "https://pebble-email-templates.s3.amazonaws.com/badges/sends-badge-1.png",
  },
  NUMBER_OF_WORKOUTS: {
    line1: "WORKOUT",
    canPluralize: true,
    getTextColor: (val) =>
      val === 50 || val % 100 === 0 ? whiteColor : textColor,
    getFeedItemText: (item) => `Completed ${item.achievementValue} workouts!`,
    getBadge: (val) =>
      val === 50 || val % 100 === 0
        ? "https://pebble-badges.s3.amazonaws.com/workouts-badge-2.png"
        : "https://pebble-badges.s3.amazonaws.com/workouts-badge-1.png",
    getEmailBadge: (val) =>
      val === 50 || val % 100 === 0
        ? "https://pebble-email-templates.s3.amazonaws.com/badges/workouts-badge-2.png"
        : "https://pebble-email-templates.s3.amazonaws.com/badges/workouts-badge-1.png",
  },
  INDOOR_PROBLEM_GRADE: {
    line1: "INDOOR",
    line2: "BOULDERING",
    align: "center",
    isGrade: true,
    getTextColor: () => whiteColor,
    getFeedItemText: (item) => {
      // const send = item.sends[0]
      return `Sent their hardest indoor boulder`
    },
    getBadge: () =>
      "https://pebble-badges.s3.amazonaws.com/problem-grade-badge-2.png",
    getEmailBadge: () =>
      "https://pebble-email-templates.s3.amazonaws.com/badges/problem-grade-badge-2.png",
  },
  INDOOR_ROUTE_GRADE: {
    line1: "INDOOR",
    line2: "ROUTE",
    align: "center",
    isGrade: true,
    getTextColor: () => whiteColor,
    getFeedItemText: (item) => {
      // const send = item.sends[0]
      return `Sent their hardest indoor route`
    },
    getBadge: () =>
      "https://pebble-badges.s3.amazonaws.com/route-grade-badge-2.png",
    getEmailBadge: () =>
      "https://pebble-email-templates.s3.amazonaws.com/badges/route-grade-badge-2.png",
  },
  OUTDOOR_PROBLEM_GRADE: {
    line1: "OUTDOOR",
    line2: "BOULDERING",
    align: "center",
    isGrade: true,
    getTextColor: () => textColor,
    getFeedItemText: (item) => {
      return `Sent their hardest outdoor problem`
    },
    getBadge: () =>
      "https://pebble-badges.s3.amazonaws.com/problem-grade-badge-1.png",
    getEmailBadge: () =>
      "https://pebble-email-templates.s3.amazonaws.com/badges/problem-grade-badge-1.png",
  },
  OUTDOOR_ROUTE_GRADE: {
    line1: "OUTDOOR",
    line2: "ROUTE",
    align: "center",
    isGrade: true,
    getTextColor: () => textColor,
    getFeedItemText: (item) => {
      return `Sent their hardest outdoor route`
    },
    getBadge: () =>
      "https://pebble-badges.s3.amazonaws.com/route-grade-badge-1.png",
    getEmailBadge: () =>
      "https://pebble-email-templates.s3.amazonaws.com/badges/route-grade-badge-1.png",
  },
  CREATED_PROBLEMS: {
    line1: "PROBLEM",
    line2: "CREATED",
    canPluralize: true,
    getTextColor: () => textColor,
    getBadge: () =>
      "https://pebble-badges.s3.amazonaws.com/problem-grade-badge-1.png",
    getEmailBadge: () =>
      "https://pebble-email-templates.s3.amazonaws.com/badges/problem-grade-badge-1.png",
  },
  CREATED_ROUTES: {
    line1: "ROUTE",
    line2: "CREATED",
    canPluralize: true,
    getTextColor: () => textColor,
    getBadge: () =>
      "https://pebble-badges.s3.amazonaws.com/route-grade-badge-1.png",
    getEmailBadge: () =>
      "https://pebble-email-templates.s3.amazonaws.com/badges/route-grade-badge-1.png",
  },
  COMPLETED_PYRAMID: {
    line1: "level",
    getTextColor: () => whiteColor,
    position: "absolute",
    left: "53px",
    top: "60px",
    top2: "62px",
    left2: "26.5px",
    getBadge: (val) =>
      val < 10
        ? "https://pebble-badges.s3.amazonaws.com/pyramid-badge-1.png"
        : "https://pebble-badges.s3.amazonaws.com/pyramid-badge-2.png",
    getEmailBadge: (val) =>
      val < 10
        ? "https://pebble-badges.s3.amazonaws.com/pyramid-badge-1.png"
        : "https://pebble-badges.s3.amazonaws.com/pyramid-badge-2.png",
  },
}

// TODO: not implementing
export const pointsMilestones = [1000, 5000, 10000, 25000]
