import dayjs from "dayjs"
import _ from "lodash"
import duration from "dayjs/plugin/duration"
import utc from "dayjs/plugin/utc"
dayjs.extend(duration)
dayjs.extend(utc)

export function getWorkoutPoints({ session, isEstimated }) {
  const { workout } = session

  let finishedExercises = isEstimated
    ? workout.exercises
    : workout.exercises.filter((ex) =>
        session.completedExerciseIds.includes(ex._id),
      )
  // if finished exercises doesn't match completed exercise ids, then probably updated workout.
  // so, just grab the first amount of exercises that correspond to the number finished
  if (
    finishedExercises.length === 0 &&
    session.completedExerciseIds.length !== 0
  ) {
    finishedExercises = workout.exercises.slice(
      0,
      session.completedExerciseIds.length,
    )
  }
  const climbExercises = finishedExercises.filter((ex) =>
    ["grade", "problem", "route"].includes(ex.exerciseType),
  )
  const durationExercises = finishedExercises.filter((ex) => !!ex.duration)
  const nonClimbExNum =
    finishedExercises.length - climbExercises.length - durationExercises.length
  const sendPoints = climbExercises.reduce((acc, at) => {
    const climb = at.problem || at.route
    const grade = at.grade || climb?.setterGrade || climb?.grade || 0
    const pts = climb?.points
      ? climb.points
      : at.sendType === "problem"
      ? Math.round(grade * 13.6)
      : Math.round(grade * 10)
    acc += pts
    return acc
  }, 0)
  const exDurTotal = durationExercises.reduce((acc, ex) => {
    return ex.duration * (ex.exerciseType === "cardio" ? 0.5 : 1) + acc
  }, 0)
  return sendPoints + exDurTotal + nonClimbExNum * 20
}

function getWorkoutRest(session) {
  const { workout } = session
  const beginningOfSession = dayjs(session.startDate)
  const endOfSession = dayjs(session.endDate)
  const duration = dayjs.duration(endOfSession.diff(beginningOfSession))

  const totalTime = Math.round(duration.asSeconds())
  const finishedExercises = workout.exercises.filter((ex) =>
    session.completedExerciseIds.includes(ex._id),
  )
  const durationExercises = finishedExercises.filter((ex) => ex.duration)
  const totalDurations = durationExercises.reduce((acc, ex) => {
    return acc + ex.duration
  }, 0)
  const leftoverExNum = finishedExercises.length - durationExercises.length
  const fullRest = totalTime - totalDurations - leftoverExNum * 30
  return workout?.exercises?.length
    ? Math.round(fullRest / finishedExercises.length)
    : 0
}

export function getSessionStats(session) {
  if (
    session.sessionType === "workout" &&
    !!session.workout &&
    !session.workout.customExercises
  ) {
    const { workout } = session
    if (!workout) {
      return {}
    }
    const beginningOfSession = dayjs(session.startDate)
    const endOfSession = dayjs(session.endDate)
    const duration = dayjs.duration(endOfSession.diff(beginningOfSession))
    const totalTime = Math.round(duration.asSeconds())
    // forcing 0:01 because dayjs is giving me 12:00 if less than a minute
    const totalTimeStr =
      totalTime < 60
        ? "0:01"
        : dayjs.utc(duration.asMilliseconds()).format("h:mm")

    const finishedExercises = workout.exercises.filter((ex) =>
      session.completedExerciseIds.includes(ex._id),
    )
    const pebblePoints = getWorkoutPoints({ session })
    const pebbleIntensity =
      totalTime && workout
        ? Math.round((pebblePoints / totalTime) * 60 * finishedExercises.length)
        : 0
    const restTime = finishedExercises.length > 1 ? getWorkoutRest(session) : 0
    const restTimeStr = dayjs
      .utc(dayjs.duration(restTime, "seconds").as("ms"))
      .format("m:ss")
    return {
      totalTime,
      // TODO: once dayjs includes duration format: https://github.com/iamkun/dayjs/pull/1202
      // then can remove replace
      totalTimeStr: totalTimeStr.replace("12:", "0:"),
      pebbleIntensity,
      pebblePoints: Math.round(pebblePoints),
      restTime,
      restTimeStr,
    }
  }
  const sendsAndAttempts = [...session.attempts, ...session.sends]

  const firstSendDate = _.minBy(sendsAndAttempts, (at) => at.date)?.date
  const beginningOfSession = dayjs(
    _.minBy([session.startDate, firstSendDate], (d) => new Date(d)),
  )
  const endOfSession = dayjs(
    _.maxBy(sendsAndAttempts, (at) => new Date(at.date))?.date,
  )
  const duration = dayjs.duration(endOfSession.diff(beginningOfSession))
  const totalTime = Math.round(duration.as("seconds"))
  const hoursDuration = duration.asHours()
  const totalTimeStr =
    Math.floor(duration.as("h")) + dayjs.utc(duration.as("ms")).format(":mm")
  const rest =
    sendsAndAttempts.length === 1
      ? 0
      : hoursDuration / (sendsAndAttempts.length - 1)
  const restTime = rest
    ? Math.round(dayjs.utc(dayjs.duration(rest, "hours").as("seconds")))
    : 0
  const restTimeStr = dayjs
    .utc(dayjs.duration(rest, "hours").as("ms"))
    .format("m:ss")
  const percentSends = sendsAndAttempts.length
    ? Math.round((session.sends.length / sendsAndAttempts.length) * 100)
    : 0
  const pebblePoints = sendsAndAttempts.reduce((acc, at) => {
    const climb = at.problem || at.route
    const grade = at.grade || climb?.setterGrade || climb?.grade || 0
    const pts = climb?.points
      ? climb.points
      : at.sendType === "problem"
      ? Math.round(grade * 13.6)
      : Math.round(grade * 10)
    acc += at.type === "send" ? pts : pts * 0.5
    return acc
  }, 0)
  const pebbleIntensity = duration.asMinutes()
    ? Math.round(
        (pebblePoints / duration.asMinutes()) *
          (session.sends.length + session.attempts.length),
      )
    : 0
  const sendPoints = session.sends.reduce((acc, at) => {
    const climb = at.problem || at.route
    const grade = at.grade || climb?.setterGrade || climb?.grade || 0
    const pts = climb?.points
      ? climb.points
      : at.sendType === "problem"
      ? Math.round(grade * 13.6)
      : Math.round(grade * 10)
    acc += pts
    return acc
  }, 0)
  return {
    totalTime,
    totalTimeStr,
    pebbleIntensity,
    pebblePoints: Math.round(pebblePoints),
    restTime,
    restTimeStr,
    sendPoints,
    percentSends,
    sendsAndAttempts,
  }
}

export default {
  getSessionStats,
}
