import React from "react"
import {
  Heading,
  IconButton,
  ListItem,
  Text,
  List,
  Link,
  Avatar,
  Flex,
  useTheme,
} from "@chakra-ui/react"
import Modal from "react-modal"
import Card from "../../common/components/Card"
import styles from "./css/UserListModal.module.css"
import { faTimes } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import TextLink from "../../common/components/TextLink"
import useColors from "../../common/hooks/useColors"

const UserListModal = ({ isOpen, title, description, users, onClose }) => {
  const { blueColor } = useColors()
  return (
    <Modal
      isOpen={isOpen === true}
      className={"modal-container flex-center"}
      overlayClassName="modal-overlay"
      ariaHideApp={false}
      contentLabel={title}
      onRequestClose={onClose}
    >
      <Card width="100%">
        <Heading as="h3" size="lg">
          {title}
        </Heading>
        {description ? <Text>{description}</Text> : null}
        <List
          height={(users.length / 3) * 33}
          spacing={2}
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
        >
          {users
            ? users.map((user, ind) => (
                <ListItem mt={2} key={user.slug + ind} width="32%">
                  <Flex align="center">
                    <Avatar
                      src={user.profile?.picture}
                      name={user.name}
                      size="sm"
                      mr={3}
                    />
                    <TextLink to={"/users/" + user.slug}>{user.name}</TextLink>
                  </Flex>
                </ListItem>
              ))
            : null}
        </List>
        <IconButton
          position="absolute"
          top={0}
          right={0}
          variant="text"
          onClick={onClose}
        >
          <FontAwesomeIcon icon={faTimes} color={blueColor} />
        </IconButton>
      </Card>
    </Modal>
  )
}

UserListModal.propTypes = {}

export default UserListModal
