import { Box, Heading, useTheme } from "@chakra-ui/react"
import * as React from "react"
import { capitalizeText } from "../../utilities/text"
import { getSessionTryData } from "../../utilities/charts"
import dynamic from "next/dynamic"
import useColors from "../../common/hooks/useColors"
const ResponsiveBar = dynamic(
  () => import("@nivo/bar").then((m) => m.ResponsiveBar),
  { ssr: false },
)

const TickLabel = ({ value, index, x, data, isSmall }) => {
  const { orangeColor, headingColor, whiteColor } = useColors()
  const gradeColor = data[index]?.gradeColor
  const fullLabel = data[index]?.gradeName || ""
  const label = fullLabel.length > 3 ? fullLabel.replace("5.", "") : fullLabel
  if (gradeColor === "transparent") {
    return null
  }
  return (
    <g transform={`translate(${x}, 0)`}>
      <defs>
        <filter x="0" y="0" width="1" height="1" id="solid">
          {/*  */}
          <feFlood flood-color={whiteColor} />
          <feComposite in="SourceGraphic" operator="xor" />
        </filter>
      </defs>
      {gradeColor ? (
        <circle
          cx={0}
          cy={0}
          r={isSmall ? 5 : 10}
          fill={gradeColor}
          style={{
            fill: gradeColor,
            stroke: "rgba(100, 100, 100, 0.3)",
          }}
        />
      ) : (
        <>
          <text
            filter="url(#solid)"
            transform="rotate(45)"
            x={-1}
            y={4}
            textAnchor="middle"
            fill={headingColor}
          >
            {label}
          </text>
          <text
            transform="rotate(45)"
            x={-1}
            y={4}
            textAnchor="middle"
            fill={headingColor}
          >
            {label}
          </text>
        </>
      )}
    </g>
  )
}

export interface ISessionChartProps {
  chartHeight?: number
  totalTicks?: number
  sendAttempts: any[]
  gradeType: string
  keys?: string[]
  colors?: string[]
  isSmall?: boolean
}

export default function SessionChart({
  chartHeight = 200,
  totalTicks = 3,
  sendAttempts,
  gradeType,
  keys = ["sends", "attempts"],
  colors,
  isSmall = false,
}: ISessionChartProps) {
  const { orangeColor, headingColor, headingLineColor } = useColors()
  colors = colors || [orangeColor, headingColor]
  const [cardWidth, setCardWidth] = React.useState(0)
  const data = sendAttempts?.length
    ? getSessionTryData({
        sends: sendAttempts,
        gradeType,
        curFeatureType: `${sendAttempts[0].sendType}s`,
        gym: sendAttempts[0][sendAttempts[0].sendType]?.gym,
      })
    : []
  let maxSendsVal = 0
  let maxAttemptsVal = 0
  let data1 = data.map((c) => {
    maxSendsVal = Math.max(maxSendsVal, c.sends.value)
    maxAttemptsVal = Math.max(maxAttemptsVal, c.attempts.value)
    return {
      ...c,
      y: c.sends.value,
    }
  })

  let data2 = data.map((c) => {
    return {
      ...c,
      y: c.attempts.value,
    }
  })
  if (data1.length === 1) {
    data1 = [
      { y: 0, gradeColor: "transparent", value: 0, x: "" },
      data1[0],
      { y: 0, gradeColor: "transparent", value: 0, x: "blah" },
    ]
  }

  if (data2.length === 1) {
    data2 = [
      { y: 0, gradeColor: "transparent", value: 0, x: "" },
      data2[0],
      { y: 0, gradeColor: "transparent", value: 0, x: "blah" },
    ]
  }
  const height = chartHeight - 30
  const hasValues = maxSendsVal || maxAttemptsVal
  const maxYVal = maxSendsVal + maxAttemptsVal
  const tickInt = Math.max(1, maxYVal / totalTicks)
  const numSendsTicks = Math.ceil(maxSendsVal / tickInt)
  const numAttemptsTicks = Math.ceil(maxAttemptsVal / tickInt)
  const graph1Height = hasValues
    ? Math.max(height * (maxSendsVal / maxYVal), 15) + 40
    : height / 2
  const graph2Height = hasValues
    ? Math.max(height * (maxAttemptsVal / maxYVal), 15) + 40
    : height / 2
  return (
    <>
      <Box mt={isSmall ? 6 : 0} height={chartHeight}>
        {!isSmall && (
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            mb={4}
          >
            {colors.map((color, ind) => {
              return (
                <Box
                  display="flex"
                  key={keys[ind]}
                  mt={2}
                  mb={0}
                  mr={2}
                  flexDirection="row"
                  alignItems="center"
                >
                  <Box bg={color} width={5} height={5} mr={2} />
                  <Heading mr={4} mb={0} as="h6" size="xs">
                    {capitalizeText(keys[ind])}
                  </Heading>
                </Box>
              )
            })}
          </Box>
        )}
        <Box position="relative" zIndex={1} height={graph1Height}>
          <Box
            position="absolute"
            top={"5px"}
            left={"29px"}
            bottom={"46px"}
            borderWidth={1}
            borderColor={headingLineColor}
            zIndex={2}
          />
          <Box
            position="absolute"
            left={"29px"}
            right={"10px"}
            bottom={"49px"}
            borderWidth={1}
            borderColor={headingLineColor}
            zIndex={0}
          />
          <ResponsiveBar
            margin={{ top: 10, right: 10, bottom: 50, left: 30 }}
            tooltip={({ data }) => null}
            enableGridX={false}
            enableGridY={false}
            keys={["y"]}
            indexBy="x"
            data={data1}
            colors={[orangeColor]}
            colorBy={"indexValue"}
            labelSkipHeight={isSmall ? 200 : 1}
            layers={[
              "grid",
              "bars",
              "totals",
              "markers",
              "legends",
              "axes",
              "annotations",
            ]}
            theme={{
              axis: {
                ticks: {
                  text: {
                    fill: headingColor,
                  },
                },
                domain: {
                  line: {
                    stroke: headingColor,
                    strokeWidth: 0,
                  },
                },
              },
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "",
              legendOffset: -40,
              legendPosition: "middle",
              tickValues: 2,
            }}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              renderTick: (obj) => {
                const { value, tickIndex, x } = obj
                return (
                  <TickLabel
                    data={data1}
                    value={value}
                    index={tickIndex}
                    isSmall={isSmall}
                    x={x}
                  />
                )
              },
            }}
          />
        </Box>
        <Box mt={"-50px"} height={graph2Height}>
          <ResponsiveBar
            reverse
            margin={{ top: 0, right: 10, bottom: 50, left: 30 }}
            tooltip={({ data }) => null}
            enableGridX={false}
            enableGridY={false}
            keys={["y"]}
            indexBy="x"
            data={data2}
            colors={[headingColor]}
            colorBy={"indexValue"}
            labelSkipHeight={isSmall ? 200 : 1}
            labelTextColor={"#fff"}
            theme={{
              axis: {
                ticks: {
                  text: {
                    fill: headingColor,
                  },
                },
                domain: {
                  line: {
                    stroke: headingLineColor,
                    strokeWidth: 1,
                  },
                },
              },
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "",
              legendOffset: -40,
              legendPosition: "middle",
              tickValues: 2,
              format: (v) => (v == 0 ? "" : v),
            }}
            axisBottom={null}
          />
        </Box>
      </Box>
    </>
  )
}
