export const problemMapZoom = 17
export const mapStyle = "mapbox://styles/sundevlohr/cj1duplt5005d2rr5nmi9d94g"
export const mapAccessToken =
  "pk.eyJ1Ijoic3VuZGV2bG9ociIsImEiOiJjaWtpang5dzIwNDIzdHpqN3E3eTdtam16In0.zggt-fx3Kr6ewWton3ssHQ"
export const mapContainerStyle = {
  height: 400,
  width: 560,
  borderRadius: 2,
}
export const mapContainerStyleWide = {
  width: typeof window === "undefined" ? 1170 : "100%",
  minWidth: 300,
  minHeight: 400,
  height: 400,
  borderRadius: 2,
}

export const mapContainerStyleFull = {
  width: "100%",
  height: "100%",
  borderRadius: 0,
  position: "absolute",
}
