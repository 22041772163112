import * as turf from "@turf/turf"
import geoViewport from "@mapbox/geo-viewport"

export const getCollection = (features) => {
  return features.reduce((ar, prob) => {
    if (!prob?.location?.coordinates) {
      return ar
    }
    return [...ar, turf.point(prob.location.coordinates)]
  }, [])
}

export const getCenter = (features) => {
  const pointsAr = getCollection(features)
  const center = turf.center(turf.featureCollection(pointsAr))
  return center.geometry
}

export const getCenterZoomFromFeats = (features, dims) => {
  const col = turf.featureCollection(getCollection(features))
  const bbox = turf.bbox(col)
  return geoViewport.viewport(bbox, dims)
}

// from: https://gist.github.com/michiel/7984227
const rad_Earth = 6378.16
const one_degree = (2 * Math.PI * rad_Earth) / 360
const one_km = 1 / one_degree

function randomInRange(from, to, fixed) {
  fixed = fixed || 10
  return (Math.random() * (to - from) + from).toFixed(fixed) * 1
}

export function jitter(lat, lng, kms, fixed) {
  return {
    lat: randomInRange(lat - kms * one_km, lat + kms * one_km, fixed),
    lng: randomInRange(lng - kms * one_km, lng + kms * one_km, fixed),
  }
}

export default {
  getCollection,
  getCenter,
  getCenterZoomFromFeats,
  jitter,
}
