import { AbsoluteCenter, Box, Flex, Heading } from "@chakra-ui/react"
import Card from "../../common/components/Card"
import Loader from "../../common/components/Loader"
import Carousel from "../../common/components/Carousel"
import { GET_SESSION } from "../../sends/graphql/sessions"
import * as React from "react"
import { useQuery } from "@apollo/client"
import dayjs from "dayjs"
import WorkoutCard from "../../workouts/components/WorkoutCard"
import { getMergedSendAttempts } from "../../utilities/charts"
import GradeContext from "../../context/GradeContext"
import SessionChart from "./SessionChart"
import AbsoluteGradient from "../../common/components/AbsoluteGradient"
import SessionStats from "./SessionStats"

export interface ISessionCardProps {
  isBox?: boolean
  sessionId: string
  chartHeight?: number
  isPublicBoard?: boolean
  isSmall?: boolean
}

export default function SessionCard({
  isBox,
  sessionId,
  chartHeight,
  isPublicBoard = false,
  isSmall = false,
}: ISessionCardProps) {
  const { loading, data } = useQuery(GET_SESSION, {
    variables: {
      id: sessionId,
    },
  })
  const session = data?.getSession || {}

  const mergedSendAttempts = getMergedSendAttempts(session)

  const sendAttempts = (mergedSendAttempts || []).map((send) => {
    if (!send.name) {
      send.name = `${session.user.firstName} tried ${
        send.problem || send.sendType === "problem"
          ? send.problem?.name || "a problem"
          : send.route?.name || "a route"
      }`
    }
    return send
  })
  const Container = isBox ? Box : Card
  return (
    <Container flex={1} display="flex">
      {loading ? (
        <Flex flex={1} alignItems="center" justifyContent="center">
          <AbsoluteGradient />
          <Loader isRelative />
        </Flex>
      ) : (
        <Box
          flex={1}
          position="relative"
          display="flex"
          alignItems={"stretch"}
          justifyContent={"stretch"}
        >
          <AbsoluteGradient />
          <Box flex={1} p={0} position="relative">
            {isPublicBoard ? (
              <GradeContext.Consumer>
                {(gradeType) => (
                  <SessionChart
                    sendAttempts={sendAttempts}
                    gradeType={gradeType}
                    chartHeight={chartHeight}
                    isSmall={isSmall}
                  />
                )}
              </GradeContext.Consumer>
            ) : (
              <Carousel>
                {session.sessionType === "workout" && (
                  <Box key="workout">
                    <WorkoutCard workout={session.workout} isBox />
                  </Box>
                )}
                {(!!sendAttempts?.length || !!session?.workout?.gym) && (
                  <Box key="chart" m={2}>
                    <GradeContext.Consumer>
                      {(gradeType) => (
                        <SessionChart
                          sendAttempts={sendAttempts}
                          gradeType={gradeType}
                          chartHeight={chartHeight}
                        />
                      )}
                    </GradeContext.Consumer>
                  </Box>
                )}
                {sendAttempts?.length > 1 ||
                session.sessionType === "workout" ? (
                  <SessionStats isInFeed key="stats" session={session} />
                ) : null}
              </Carousel>
            )}
          </Box>
        </Box>
      )}
    </Container>
  )
}
