export const exerciseTypes = [
  // { value: "grade", label: "Climb by grade" },
  {
    value: "fingerboard",
    label: "Fingerboard",
    startLabel: "Start Fingerboarding",
  },
  { value: "campus", label: "Campus Board", startLabel: "Start Campusing" },
  {
    value: "system",
    label: "System board",
    startLabel: "Start System boarding",
  },
  {
    value: "stretch",
    label: "Stretching",
    startLabel: "Start Stretching",
  },
  { value: "cardio", label: "Cardio", startLabel: "Start Exercise" },
  {
    value: "weights",
    label: "Strength",
    startLabel: "Start Strength Training",
  },
  // {
  //   value: "video",
  //   label: "Video",
  //   startLabel: "Start the Video",
  // },
]

export const clearFilterObj = {
  creator: null,
  gyms: [],
  types: [],
  minTime: null,
  maxTime: null,
  maxProblemGrade: null,
  maxRouteGrade: null,
  climbTypes: [],
  isFeatured: false,
}

export const featuredWorkoutSections = [
  {
    title: "Fingerboard Workouts",
    data: [
      [
        {
          name: "Max Hangs",
          _id: "",
          image: "https://gym-brand-images.s3.amazonaws.com/limit-finger.jpg",
          screen: "Workout",
          params: {
            workoutId: "5f90430624405f000832b916",
          },
        },
        {
          name: "10 - 10 repeaters",
          _id: "",
          image:
            "https://gym-brand-images.s3.amazonaws.com/repeat-finger-2.jpg",
          screen: "Workout",
          params: {
            workoutId: "6138260cd82bfc000744ffcd",
          },
        },
      ],
    ],
  },
  {
    title: "Pull Workouts",
    data: [
      [
        {
          name: "Max Pull-ups",
          image: "https://gym-brand-images.s3.amazonaws.com/pullups.jpg",
          screen: "Workout",
          params: {
            workoutId: "61466c982a43a90009b3f3b5",
          },
        },
        {
          name: "Pyramids",
          image: "https://gym-brand-images.s3.amazonaws.com/pullups-2.jpg",
          screen: "Workout",
          params: {
            workoutId: "6158d8c5ba901e000818fcdf",
          },
        },
      ],
    ],
  },
]
