import _ from "lodash"

const areaBlue = "/images/map-icons/area-blue.png"
const areaOrange = "/images/map-icons/area-orange.png"
const climbBlue = "/images/map-icons/climb-blue.png"
const climbOrange = "/images/map-icons/climb-orange.png"
const guideBlue = "/images/map-icons/guide-blue.png"
const guideOrange = "/images/map-icons/guide-orange.png"
const boulderBlue = "/images/map-icons/boulder-blue.png"
const boulderOrange = "/images/map-icons/boulder-orange.png"
const routeBlue = "/images/map-icons/route-blue.png"
const routeOrange = "/images/map-icons/route-orange.png"
const mediaBlue = "/images/map-icons/media-blue.png"
const mediaOrange = "/images/map-icons/media-orange.png"
const buildingOrange = "/images/map-icons/building-orange.png"
const buildingBlue = "/images/map-icons/building-blue.png"

// default zoom for routes and boulders
export const defaultZoom = 16
export const defaultLocation = {
  isDefault: true,
  latitude: 37.0902,
  longitude: -95.7129,
}

export const getMapIcon = (type, isPrimary) => {
  switch (type) {
    case "guide":
      return isPrimary ? "square-15-orange" : "square-15"
    case "area":
      return isPrimary ? "circle-15-orange" : "circle-15"
    case "problem":
    default:
      return isPrimary ? "triangle-15-orange" : "triangle-15"
  }
}
export const getMapIconPNG = (type, isPrimary) => {
  switch (type) {
    case "guide":
      return isPrimary ? guideOrange : guideBlue
    case "area":
      return isPrimary ? areaOrange : areaBlue
    case "problem":
      return isPrimary ? boulderOrange : boulderBlue
    case "route":
      return isPrimary ? routeOrange : routeBlue
    case "media":
      return isPrimary ? mediaOrange : mediaBlue
    case "gym":
      return isPrimary ? buildingOrange : buildingBlue
    default:
      return isPrimary ? climbOrange : climbBlue
  }
}
const areaBlueStatic =
  "https%3A%2F%2Fs3.us-east-2.amazonaws.com%2Fasend-assets%2Fmapicons%2Farea-blue_static.png"
const areaOrangeStatic =
  "https%3A%2F%2Fs3.us-east-2.amazonaws.com%2Fasend-assets%2Fmapicons%2Farea-orange_static.png"
const guideBlueStatic =
  "https%3A%2F%2Fs3.us-east-2.amazonaws.com%2Fasend-assets%2Fmapicons%2Fguide-blue_static.png"
const guideOrangeStatic =
  "https%3A%2F%2Fs3.us-east-2.amazonaws.com%2Fasend-assets%2Fmapicons%2Fguide-orange_static.png"
const boulderBlueStatic =
  "https%3A%2F%2Fs3.us-east-2.amazonaws.com%2Fasend-assets%2Fmapicons%2Fboulder-blue_static.png"
const boulderOrangeStatic =
  "https%3A%2F%2Fs3.us-east-2.amazonaws.com%2Fasend-assets%2Fmapicons%2Fboulder-orange_static.png"
const routeBlueStatic =
  "https%3A%2F%2Fs3.us-east-2.amazonaws.com%2Fasend-assets%2Fmapicons%2Froute-blue_static.png"
const routeOrangeStatic =
  "https%3A%2F%2Fs3.us-east-2.amazonaws.com%2Fasend-assets%2Fmapicons%2Froute-orange_static.png"
export const getStaticMapIconURL = (type, isPrimary) => {
  switch (type) {
    case "guide":
      return isPrimary ? guideOrangeStatic : guideBlueStatic
    case "area":
      return isPrimary ? areaOrangeStatic : areaBlueStatic
    case "problem":
      return isPrimary ? boulderOrangeStatic : boulderBlueStatic
    case "route":
      return isPrimary ? routeOrangeStatic : routeBlueStatic
    default:
    // return isPrimary ? climbOrangeStatic : climbBlueStatic
  }
}

function getTypeZoom(type) {
  switch (type) {
    case "gym":
      return 0.25
    case "guide":
      return 0.17
    case "area":
      return 0.3
    case "problem":
    case "route":
      return 0.2
    default:
    // return isPrimary ? climbOrangeStatic : climbBlueStatic
  }
}

export function getMapBounds(filteredFeatures = []) {
  const lngBounds = filteredFeatures.map((feat) => feat.location.coordinates[0])
  const latBounds = filteredFeatures.map((feat) => feat.location.coordinates[1])
  // array for bounding box
  const bounds =
    filteredFeatures && filteredFeatures.length !== 0
      ? [
          [_.min(lngBounds), _.min(latBounds)],
          [_.max(lngBounds), _.max(latBounds)],
        ]
      : [
          [-170, -70],
          [170, 70],
        ]
  return bounds
}

export function getLayerImages() {
  const types = ["guide", "gym", "area", "route", "problem"]
  return types.reduce((acc, type) => {
    try {
      if (typeof Image !== undefined) {
        const image = new Image()
        image.src = getMapIconPNG(type)
        const imagePrimary = new Image()
        imagePrimary.src = getMapIconPNG(type, true)
        return {
          ...acc,
          [`${type}`]: [`layerImage${type}`, image],
          [`${type}Primary`]: [`layerImage${type}Primary`, imagePrimary],
          [`${type}Zoom`]: getTypeZoom(type),
        }
      }
    } catch (e) {
      return acc
    }
  }, {})
}

export const getZoomFromType = (type) => {
  switch (type) {
    case "area":
      return [14]
    case "problem":
    case "route":
      return [17]
    case "guide":
    default:
      return [13]
  }
}

export default {
  getMapIcon,
  getMapIconPNG,
  defaultZoom,
  defaultLocation,
}
