import React, { useState } from "react"
import { Input, Button, Box, Flex, Divider, useTheme } from "@chakra-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons"
import { faUser } from "@fortawesome/free-solid-svg-icons"
import { CREATE_COMMENT, GET_COMMENTS } from "../../guides/graphql/comments"
import useColors from "../../common/hooks/useColors"
import { useMutation } from "@apollo/client"
import { imageHandler } from "../../utilities/imageHandler"

const CommentInput = ({ loggedInUser, type, _id }) => {
  const { blueColor, whiteColor } = useColors()
  const [comment, setComment] = useState("")
  const [isSaving, setIsSaving] = useState(false)
  const loggedInUserPic = loggedInUser?.profile?.picture

  const [createComment] = useMutation(CREATE_COMMENT, {
    refetchQueries: [
      {
        query: GET_COMMENTS,
        variables: {
          _id,
          type,
        },
      },
    ],
  })

  const resizedPicture = imageHandler(loggedInUser?.profile?.picture, {
    resize: { width: 60, fit: "cover" },
  })
  return (
    <Box mt={5} mx={5}>
      <Flex alignItems={"center"}>
        {resizedPicture ? (
          <Box
            flex="none"
            backgroundImage={`url(${resizedPicture})`}
            borderRadius={"100%"}
            backgroundSize="cover"
            height={30}
            width={30}
          />
        ) : (
          <Box
            flex="none"
            borderRadius={"100%"}
            bg="white"
            height={30}
            width={30}
            alignItems="center"
            display="flex"
            justifyContent="center"
          >
            <FontAwesomeIcon size="2x" color={blueColor} icon={faUser} />
          </Box>
        )}
        <Input
          mx={2}
          placeholder="Comment"
          textarea
          value={comment}
          onChange={(e) => {
            setComment(e.target.value)
          }}
        />
        <Button
          variant="primary"
          disabled={comment === ""}
          onClick={async () => {
            setIsSaving(true)
            try {
              const res = await createComment({
                variables: {
                  type,
                  _id,
                  comment,
                },
                optimisticResponse: {
                  createComment: {
                    __typename: "Comment",
                    type: "comment",
                    problem: null,
                    route: null,
                    area: null,
                    guide: null,
                    send: null,
                    feedItem: null,
                    media: null,
                    _id: "",
                    comment: comment,
                    date: new Date(),
                    user: loggedInUser,
                  },
                },
              })
              if (res?.data?.createComment?._id) {
                setComment("")
              }
              setIsSaving(false)
            } catch (err) {
              setIsSaving(false)
            }
          }}
        >
          {isSaving ? (
            <FontAwesomeIcon color={whiteColor} icon={faSpinnerThird} spin />
          ) : (
            "Comment"
          )}
        </Button>
      </Flex>
      <Divider />
    </Box>
  )
}

export default CommentInput
