import React, { useEffect, useState, useRef } from "react"
import img from "next/image"
import styles from "./css/MediaEmbed.module.css"
import Measure from "react-measure"

function transformIframe(ifStr, w, h, isAutoHeight) {
  const iframeStr = ifStr.replace(/width=" /, 'width="')
  const regexFound = iframeStr.match(/width="(\d*?)" height="(\d*?)"/)
  const width = regexFound && regexFound[1]
  const height = regexFound && regexFound[2]
  const ratio = width / height
  const newWidth = w
  const newHeight = isAutoHeight ? w / ratio : Math.min(w / ratio, h)
  const newIframeStr = iframeStr.replace(
    /width="(\d*?)" height="(\d*?)"/,
    `width="${newWidth}" height="${newHeight}"`,
  )
  return newIframeStr
}

const MediaEmbed = (props) => {
  const iframeRef = useRef()
  const { oembed, isUnstyled, isAutoHeight } = props
  const [iframeWidth, setIframeWidth] = useState(0)
  const [iframeHeight, setIframeHeight] = useState(0)
  useEffect(() => {
    if (!iframeRef.current) {
      return
    }
    var doc = iframeRef.current.contentWindow.document
    doc.open()
    doc.write(oembed.html)
    doc.close()
  }, [oembed.html])
  if (!oembed.thumbnail_url) {
    return null
  }

  if (oembed.provider_name) {
    if (oembed.provider_name === "Instagram") {
      return (
        <div>
          <iframe
            ref={iframeRef}
            title="instagram"
            width={600}
            height={920}
            style={{ maxWidth: "100%" }}
          />
        </div>
      )
    }
    if (oembed.html.indexOf("iframe") === -1) {
      return (
        <img
          className={styles["media-img"]}
          alt={oembed.title}
          src={oembed.thumbnail_url}
        />
      )
    }

    return (
      <Measure
        bounds
        onResize={(contentRect) => {
          setIframeWidth(contentRect.bounds.width)
          setIframeHeight(contentRect.bounds.height)
        }}
      >
        {({ measureRef }) => {
          const iframeStr = transformIframe(
            oembed.html.replace(/&lt/g, "<").replace(/&gt/g, ">"),
            iframeWidth,
            iframeHeight,
            isAutoHeight,
          )
          return (
            <div
              ref={measureRef}
              className={styles[isUnstyled ? "media-unstyled" : "media-iframe"]}
              dangerouslySetInnerHTML={{
                __html: iframeStr,
              }}
            />
          )
        }}
      </Measure>
    )
  }
  // no specific provider, but has image
  return (
    <img
      alt={oembed.title}
      width="100%"
      height="auto"
      src={oembed.url || oembed.thumbnail_url}
    />
  )
}

export default MediaEmbed
